import * as schemaItemTypes from './schemaItemTypes';
import { rules } from './validationRules';

const dependentProperties = {
  DESCRIPTION: 'description',
  IS_INFORM: 'isInform',
  FILE: 'file',
};

export const schema = [
  {
    type: schemaItemTypes.STRING,
    prop: dependentProperties.DESCRIPTION,
    label: 'label.comment',
    textarea: true,
  },
  {
    type: schemaItemTypes.BOOLEAN,
    prop: dependentProperties.IS_INFORM,
    label: 'tasks.visible_for_client',
  },
  {
    label: 'label.attached_files',
    prop: dependentProperties.FILE,
    type: schemaItemTypes.FILE_LIST,
    rules: [rules.ALL_LOADED],
    editable: true,
    accept: '.jpg, .jpeg, .png, .pdf',
  },
];

export const schemaForMobile = schema.filter(
  item => item.type !== schemaItemTypes.BOOLEAN && item.prop !== dependentProperties.IS_INFORM
);

export default schema;
